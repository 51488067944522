* {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

body {
  background: #000000c7;
  color: white;
  background-image: url("../public/img/bg-1.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.ul > a {
  color: inherit;
  text-decoration: none;
  display: block;
  font-size: 12px;
  line-height: 2;
}

.nav-footer {
  background: radial-gradient(
    circle,
    rgba(255, 86, 246, 0.1) 0%,
    rgba(0, 241, 255, 0.1) 50%,
    rgba(254, 254, 60, 0.1) 100%
  );
}

.paragraph {
  line-height: 2;
}

.style-btn {
  background: linear-gradient(90deg, #ff35ca 0%, #32ffff 50%, #fefe3c 100%);
  padding: 10px 35px !important;
  font-weight: 600 !important;
  border-radius: 4px;
  border: 0;
}

.style-btn2 {
  border-radius: 4px;
  font-weight: 500 !important;
  padding: 5px 35px;
  border: solid 1px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(90deg, #ff35ca 0%, #32ffff 50%, #fefe3c 100%);
  background-origin: border-box;
  box-shadow: 200px 1000px 10px #000000f1 inset;
  color: #fff;
}

.style-btn3 {
  border-radius: 4px;
  font-weight: 500 !important;
  padding: 5px 35px;
  border: solid 1px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(90deg, #ff35ca 0%, #32ffff 50%, #fefe3c 100%);
  background-origin: border-box;
  box-shadow: 200px 1000px 10px #000000 inset;
  color: #fff;
}

button.style-btn2:hover {
  box-shadow: none;
  font-weight: 500 !important;
  color: white;
}

.partner {
  margin-top: 100px !important;
  margin-bottom: 50px !important;
  border-style: solid;
}

.partnerpic {
  /* width: 150px;
  max-height: 100px; */
  object-fit: contain;
  /* margin: 40px auto; */
  display: block;
}

.ticket {
  text-transform: uppercase;
  text-decoration: none;
  color: #ffbd14;
  word-spacing: 5px;
}

.lwc-border {
  border-bottom: 3px solid;
  border-color: #ffbd3e;
}

.lwc-line {
  background: linear-gradient(90deg, #febd3c 0%, #febd3c00 100%);
  padding: 5px 35px !important;
  font-weight: 500 !important;
}

.rainbow {
  text-transform: uppercase;

  font-weight: bold;
  display: inline;
  background: linear-gradient(to right, #ef81f1 0%, #33ffff 50%, #ffff53 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5rem;
}

.absolute {
  position: absolute;
  bottom: -20px;
  right: -20px;
}

.menu-item {
  margin-inline: 10px;

  color: white !important;
  /* text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000,
    -1px -1px 0 #000; */

  /* border: solid 0px; */
}

.menu-item > a {
  border-radius: 4px;
  padding-inline: 35px;
  color: white;
  font-weight: 300;
  /* text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000,
    -1px -1px 0 #000; */
}

.menu-item :hover {
  background: linear-gradient(90deg, #ff35ca 0%, #32ffff 50%, #fefe3c 100%);
  border-radius: 4px;
  color: white;
}

.menu-active {
  background: linear-gradient(90deg, #ff35ca 0%, #32ffff 50%, #fefe3c 100%);
  border-radius: 4px;
}
.menu-active > a {
  color: #000;
  font-weight: 600;
}

.menu-item-dropdown {
  border-radius: 4px;
  padding-inline: 35px;
  color: white;
  /* text-shadow:  ; */
  /* text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000,
    -1px -1px 0 #000; */
}

.menu-item-dropdown > a {
  color: white;
}

.menu-item-dropdown :hover {
  background: white;
  border-radius: 4px;
  color: #000;
}

.menu-active-dropdown {
  border-radius: 4px;
  color: #000 !important;
}

.menu-active-dropdown > a {
  padding-block: 5px;
  background: white !important;
  border-radius: 4px;
  color: #000 !important;
}

.pentagon-1 {
  position: relative;
  width: 200px;
  height: 150px;
  background: #4169e1;
  clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 00% 100%, 0 30%);
  border-radius: 10px;
}

/* button.menu-active:hover,
active {
  box-shadow: none;
  color: black;
  border: solid 2px #000;
  font-weight: 500;
} */

.re-icon {
  position: relative;
}

.abs-icon {
  position: absolute;
  top: 100px;
  right: 100px;
  width: 200px;
  height: 100px;
}

.slick-dots li button:before {
  color: white;
  /* width: 30px;
  height: 30px; */
}

.slick-dots {
  position: absolute;
  bottom: 30px;
}

.box-1 {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 800px;
  height: 100%;
  min-height: 400px;
  clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%, 0 25%);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
}
